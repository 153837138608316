import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../Traduction/i18n';
import Home from '../../Assets/icons/home.svg';
import Works from '../../Assets/icons/works.svg';
import AboutMe from '../../Assets/icons/aboutme.svg';
import Contact from '../../Assets/icons/contact.svg';


function Navbar(props) {
  const { t } = useTranslation();
  const { darkMode, setDarkMode } = props;
  function handleDarkModeToggle() {
    setDarkMode(!darkMode);
  }

  const location = useLocation();

  return (
    <header className="MenuPrincipale">
      <nav className="Navbar">

        {/* darkmode button */}
        <div className='toggle-switch'>
          <label>
            <input type='checkbox' onClick={handleDarkModeToggle} />
            <span className='slider'></span>
          </label>
        </div>

        <div className="midleNav">
          <li className={`Menu-Items ${location.pathname === '/' ? 'active' : ''}`}>
            <Link to="/"><span>{t('Home')}</span>
              <img src={Home} className={`Nav-Icon ${location.pathname === '/' ? 'active' : ''}`} alt="logo" />
            </Link>
          </li>
          <li className={`Menu-Items ${location.pathname === '/works' ? 'active' : ''}`}>
            <Link to="/works"><span>{t('Projects')}</span>
              <img src={Works} className={`Nav-Icon ${location.pathname === '/' ? 'active' : ''}`} alt="logo" />
            </Link>
          </li>
          <li className={`Menu-Items ${location.pathname === '/aboutme' ? 'active' : ''}`}>
            <Link to="/aboutme"><span>{t('About')}</span>
              <img src={AboutMe} className={`Nav-Icon ${location.pathname === '/' ? 'active' : ''}`} alt="logo" />
            </Link>
          </li>
        </div>

        <Link to="/contact" id="talk" >
          <span>{t('Contact')}</span>
          <li className={`Menu-Items ${location.pathname === '/contact' ? 'active' : ''}`}>
            <img src={Contact} className={`Nav-Icon ${location.pathname === '/contact' ? 'active' : ''}`} alt="logo" />
          </li>
        </Link>

      </nav>


    </header>
  );
}

export default Navbar;
