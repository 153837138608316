// src/Components/Modal/SafariModal.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../Traduction/i18n';

// const { t } = useTranslation();

function SafariModal({ onClose }) {
    const { t } = useTranslation();
    return (
        <div className="safari-modal">
            <div className="safari-modal-content">
                <h2>🚧 {t('Site en travaux')} 🚧</h2>
                <p>{t('Ce site est actuellement en travaux pour les utilisateurs de ')}<b>Safari</b>, {t('veuillez utiliser un autre navigateur pour le moment.')}</p>
                <button onClick={onClose}>Fermer</button>
            </div>
        </div>
    );
}

export default SafariModal;
