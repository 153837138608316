import { Route, Routes/* , useLocation */ } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
/* import { AnimatePresence, motion } from 'framer-motion'; */



/* Components */
import Navbar from '../Navbar/Navbar';
import PrincipalPage from '../PrincipalPage/PrincipalPage';
import Footer from '../Footer/Footer';
import Works from '../Works/Works';
import AboutMe from '../AboutMe/AboutMe';
import Contact from '../Contact/Contact';
import SafariModal from '../SafariModal/SafariModal';


/* import Footer from '../Footer/Footer'; */
function App() {
    const [darkMode, setDarkMode] = useState(false);
    /* const location = useLocation(); */
    const [showSafariModal, setShowSafariModal] = useState(false);

    useEffect(() => {
        if (isSafari()) {
            setShowSafariModal(true);
        }
    }, []);

    function isSafari() {
        const userAgent = navigator.userAgent.toLowerCase();
        return userAgent.includes('safari') && !userAgent.includes('chrome');
    }

    function closeModal() {
        setShowSafariModal(false);
    }
    return (

        <Routes /* loaction={location} key={location.pathname} */>

            <Route path="/" element={<>
                <div className={darkMode ? 'nav-dark' : 'nav-light'}>
                    <Navbar darkMode={darkMode} setDarkMode={setDarkMode} />
                    {/* swipe effect */}

                    <PrincipalPage darkMode={darkMode} setDarkMode={setDarkMode} />
                    <div className="App">
                        {showSafariModal && <SafariModal onClose={closeModal} />}
                        {/* Reste de votre application */}
                    </div>
                    <Footer />

                </div>

                {/* <Footer /> */}
            </>} />

            <Route path="/works" element={<>
                <div className={darkMode ? 'nav-dark homeback-black' : 'nav-light homeback-white'}>
                    <Navbar darkMode={darkMode} setDarkMode={setDarkMode} />


                    <Works />


                    {/* <Footer /> */}
                </div>
            </>} />

            <Route path="/aboutme" element={<>
                <div className={darkMode ? 'nav-dark homeback-black' : 'nav-light homeback-white'}>
                    <Navbar darkMode={darkMode} setDarkMode={setDarkMode} />
                    <AboutMe />
                </div>
            </>} />

            <Route path="/contact" element={<>
                <div className={darkMode ? 'nav-dark homeback-black' : 'nav-light homeback-white'}>
                    <Navbar darkMode={darkMode} setDarkMode={setDarkMode} />
                    <Contact />
                    {/* <Footer /> */}
                </div>
            </>} />

        </Routes>
    );

}

export default App;

/*                 <Route path="/" element={<>
                    <div className={darkMode ? 'nav-dark' : 'nav-light'}>
                        <Navbar darkMode={darkMode} setDarkMode={setDarkMode} />

                        <motion.div className="background" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }}>

                            <PrincipalPage darkMode={darkMode} setDarkMode={setDarkMode} />
                            <Footer />
                        </motion.div>

                    </div>

                </>} /> */