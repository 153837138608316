import React from "react";

function NavbarPoints(props) {

    function scrollToTop() {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }

    function scrollToRefSystem(ref) {
        // si la position de la ref est au dessus de notre scroll alors on fait un ref.current.scrollIntoView({ behavior: "smooth", block: "start" }); sinon on fait un ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
        // on récupère la position de la top position de la ref par rapport au scroll
        var topPosition = ref.current.getBoundingClientRect().top + window.pageYOffset;
        /* console.log("positionRef: " + topPosition); */
        var positionTopOfScreen = window.pageYOffset;
        /* console.log("positionScreen: " + positionTopOfScreen); */

        if (topPosition < positionTopOfScreen) {
            ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
            /* console.log('bot to top'); */
        } else {
            ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
            /* console.log('top to bot'); */
        }


    }

    return (
        <div className="NavbarPoints">
            <div className="boutonZone">
                <div className='ButtonWithText'>
                    <span className="textOverButton" style={{ opacity: 0, transition: 'opacity 0.2s ease' }}>Accueil</span>
                    <button className="PointButton" onClick={() => scrollToTop()} onMouseOver={() => {
                        document.getElementsByClassName("textOverButton")[0].style.opacity = '1';
                    }} onMouseOut={() => {
                        document.getElementsByClassName("textOverButton")[0].style.opacity = '0';
                    }}>
                    </button>
                </div>
                <span className="stick"></span>
            </div>
            {props.RefList.map((ref, index) => (
                <div className="boutonZone">
                    <div className='ButtonWithText'>
                        <span className="textOverButton" style={{ opacity: 0, transition: 'opacity 0.2s ease' }}>{props.TitlesList[index]}</span>
                        <button id='relative' className="PointButton" key={index} onClick={() => scrollToRefSystem(ref)} onMouseOver={() => {
                            document.getElementsByClassName("textOverButton")[index + 1].style.opacity = '1';
                        }} onMouseOut={() => {
                            document.getElementsByClassName("textOverButton")[index + 1].style.opacity = '0';
                        }}>
                        </button>
                    </div>
                    {index !== props.RefList.length - 1 && <span className="stick"></span>}
                </div>
            ))}
        </div>
    );
}

export default NavbarPoints;