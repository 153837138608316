import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import Principal from './Principal';
import TimelineAboutMe from './Timeline';
import { useState } from 'react';
import MySkills from './MySkills';


export default function AboutMe() {
    const AutoplaySlider = withAutoplay(AwesomeSlider);
    const [timelineOpacity, setTimelineOpacity] = useState(1);
    const handleTransitionStart = () => {
        setTimelineOpacity(0);
    };




    return (

        <AutoplaySlider play={true}
            cancelOnInteraction={false} // should stop playing on user interaction
            interval={45000}
            fillParent={true}

            className="slider-container PrincipalAboutMe">


            <div className='AboutMeCard'>
                <Principal />
            </div>
            <div >
                <MySkills />

            </div>
            <div>
                <TimelineAboutMe />
            </div>
        </AutoplaySlider>

    );
}
