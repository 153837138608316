import React, { useState } from 'react';
import Mail from '../../Assets/icons/mail.svg';
import LinkedIn from '../../Assets/icons/linkedin.svg';
import GitHub from '../../Assets/icons/github.svg';
import { useTranslation } from 'react-i18next';
import i18n from '../Traduction/i18n';



function Footer() {
  const { t } = useTranslation();
  const [clicked, setClicked] = useState(false);

  return (
    <footer>
      <div className='footeritem footeremail'>
        <button
          onClick={() => {
            navigator.clipboard.writeText('iliass.benammar@gmail.com');
            setClicked(true);
          }}
          onMouseLeave={() => {
            setClicked(false);
          }}
        >
          <img src={Mail} className="footer__logo" alt="logo" />
          <span>{clicked ? t('Copied ! 📋') : 'iliass.benammar@gmail.com'}</span>
        </button>
      </div>

      <div className='footeritem' >
        <a href="https://www.linkedin.com/in/iliassp8/" target="_blank" rel="noreferrer">
          <img src={LinkedIn} className="footer__logo" alt="logo" />
        </a>
      </div>

      <div className='footeritem' >
        <a href="https://github.com/SpinHit" target="_blank" rel="noreferrer">
          <img
            src={GitHub}
            className="footer__logo"
            alt="logo"
          />
        </a>
      </div>

    </footer>
  );
}

export default Footer;
