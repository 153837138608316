import Imagesolo from '../../Assets/man-with-long-hat.svg';
import Blacksolo from '../../Assets/Illustrations/White.svg';

import { useTranslation } from 'react-i18next';
import i18n from '../Traduction/i18n';

function PrincipalPage(props) {
    const { t } = useTranslation();
    const { darkMode, setDarkMode } = props;
    return (
        <div className="principal-page">

            <div className="left-sideprincipal">
                <h2>{t('Hello, I am')}</h2>
                <h1>{t('Iliass')}</h1>
                <p>{t('Developer')}</p>
            </div>

            <div className="midle-sideprincipal">
                {darkMode ? <img src={Blacksolo} alt="logo" /> : <img src={Imagesolo} alt="logo" />}
            </div>

        </div>
    );
}

export default PrincipalPage;
