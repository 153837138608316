import { useForm } from "react-hook-form";
import { useReducer } from "react";
import emailjs from 'emailjs-com';
import { emailServiceId, emailTemplateId, emailUserId } from './emailConfig';
import PhoneIcon from '../../Assets/icons/phoneBlack.svg';
import LinkedinIcon from '../../Assets/icons/linkedin.svg';
import GithubIcon from '../../Assets/icons/github.svg';
import Mail from '../../Assets/icons/mail.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import i18n from '../Traduction/i18n';

const initialState = { clicked: false, clicked2: false };

function reducer(state, action) {
    switch (action.type) {
        case 'SET_CLICKED':
            return { ...state, clicked: action.payload };
        case 'SET_CLICKED2':
            return { ...state, clicked2: action.payload };
        default:
            return state;
    }
}

function ContactForm() {
    const { t } = useTranslation();
    const [state, dispatch] = useReducer(reducer, initialState);
    const phoneNumber = "&#48;&#55; &#54;&#55; &#56;&#56; &#55;&#55; &#57;&#56;";
    const email = "iliass&#46;benammar&#64;gmail&#46;com";
    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    const onSubmit = async (data) => {
        try {
            await emailjs.sendForm(emailServiceId, emailTemplateId, document.querySelector('form'), emailUserId);
            toast.success(t('Email envoyé avec succès'));
            reset();
        } catch (error) {
            toast.error(t("Erreur lors de l'envoi de l'email"));
        }
    };

    return (
        <div className="center">
            <ToastContainer />
            <div className="contact">
                <form onSubmit={handleSubmit(onSubmit)} className="contact-form">
                    <h1>{t('Me contacter')}</h1>
                    <div className="inputBox">
                        <div className="input-zone">
                            <input
                                type="text"
                                id="name"
                                placeholder={t('Nom')}
                                // t -> "Ce champ est requis"
                                {...register("name", { required: t("Ce champ est requis") })}
                                maxLength={30} // ajouter maxLength pour limiter à 30 caractères
                            />
                            {errors.name && <span>{errors.name.message}</span>}
                        </div>

                        <div className="input-zone">
                            <input
                                type="email"
                                id="email"
                                placeholder={t('Email')}
                                {...register("email", { required: t("Ce champ est requis") })}
                                maxLength={50} // ajouter maxLength pour limiter à 50 caractères
                            />
                            {errors.email && <span>{errors.email.message}</span>}
                        </div>

                        <div className="input-zone">
                            <textarea
                                id="message"
                                placeholder={t('Message')}
                                {...register("message", { required: t("Ce champ est requis") })}
                                maxLength={500} // ajouter maxLength pour limiter à 500 caractères
                            />
                            {errors.message && <span>{errors.message.message}</span>}
                        </div>
                        <button type="submit">{t('Envoyer')}</button>

                    </div>
                </form>
                <div className="contact-Footer">

                    <div className="footeritem footeremail">
                        <button
                            onClick={() => {
                                navigator.clipboard.writeText('iliass.benammar@gmail.com');
                                dispatch({ type: 'SET_CLICKED', payload: true });
                            }}
                            onMouseLeave={() => {
                                dispatch({ type: 'SET_CLICKED', payload: false });
                            }}
                        >
                            <img src={Mail} className="footer__logo" alt="logo" />
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: state.clicked ? t('Copied ! 📋') : email,
                                }}
                            ></span>

                        </button>
                    </div>

                    <div id="testi" className="footeritem footeremail">
                        <button
                            onClick={() => {
                                navigator.clipboard.writeText('07 67 88 77 98');
                                dispatch({ type: 'SET_CLICKED2', payload: true });
                            }}
                            onMouseLeave={() => {
                                dispatch({ type: 'SET_CLICKED2', payload: false });
                            }}
                        >

                            <img src={PhoneIcon} className="footer__logo" alt="logo" />
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: state.clicked2 ? t('Copied ! 📋') : phoneNumber,
                                }}
                            ></span>

                        </button>
                    </div>

                    <div className="contact-Left-Footer-Item footeritem">
                        <a href="https://www.linkedin.com/in/iliassp8/" target="_blank" rel="noreferrer">
                            <img src={LinkedinIcon} alt="linkedin" className="footer__logo" />
                        </a>
                    </div>
                    <div className="contact-Left-Footer-Item footeritem">
                        <a href="https://github.com/SpinHit" target="_blank" rel="noreferrer">
                            <img src={GithubIcon} alt="github" className="footer__logo" />
                        </a>
                    </div>


                </div>
            </div>
        </div>
    );
}

export default ContactForm;