import React, { useState, useEffect } from "react";

const AppearOnScroll = (props) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 100) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div
            style={{
                visibility: isVisible ? "visible" : "hidden",
                opacity: isVisible ? 1 : 0,
            }}

            className="AppearOnScroll"
        >
            {props.children}
        </div>
    );
};

export default AppearOnScroll;
