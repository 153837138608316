import React from "react";

export function WorkBox(props) {
    return (
        <a className="WorkBox" href={props.link} target="_blank" rel="noreferrer">
            <img src={props.image} alt="logo" />
            <div className="WorkBox-text">
                <h2>{props.title}</h2>

                <p>{props.description}</p>
                <div className="WorkBox-tech">
                    {props.technologies &&
                        props.technologies.map((tech, index) => (
                            <span key={index} className="tech">
                                {tech}
                            </span>
                        ))}
                </div>
            </div>
        </a>
    );
}

export function SimpleWorkBox(props) {
    return (
        <a className="WorkBox" href={props.link} id='relative' target="_blank" rel="noreferrer">
            <img src={props.image} alt="logo" id='width100' />
            <h3 id="absolutecenter">{props.title}</h3>
        </a>
    );
}
